import { Button } from 'components/Button'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  /* Desktop */
  @media (min-width: 1025px) {
    flex-direction: row;
  }
`

export const ContainerImgs = styled.div`
  width: 100%;
  height: 279px;

  /* Tablet */
  @media (min-width: 768px) {
    width: 100%;
    height: 50%;
  }

  /* Desktop */
  @media (min-width: 1025px) {
    align-self: flex-start;
    height: 100%;
    width: 70%;
  }
`

export const CoverImg = styled.img`
  height: 279px;
  width: 100%;

  /* Tablet */
  @media (min-width: 768px) {
    height: 568px;
  }

  /* Desktop */
  @media (min-width: 1025px) {
    height: auto;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: 27px;
  left: 32px;
  z-index: 2;

  /* Tablet */
  @media (min-width: 768px) {
    top: 67px;
  }

  /* Desktop */
  @media (min-width: 1025px) {
    top: 85vh;
  }
`

export const LogoMedSearch = styled.img``

export const LogoMedBeauty = styled.img`
  margin-top: 8px;

  /* Tablet */
  @media (min-width: 768px) {
    margin-top: 16px;
  }
`

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100vh - 279px);

  /* Desktop */
  @media (min-width: 1025px) {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const LoginCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 64px);
  max-width: 541px;
  background: var(--background);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: -100px;
  padding: 32px 16px;

  /* Tablet */
  @media (min-width: 768px) {
    width: calc(100% - 198px);
    margin-top: -120px;
    padding: 40px 48px;
  }

  /* Desktop */
  @media (min-width: 1025px) {
    margin-top: 0;
    max-width: 551px;
    height: 279px;
    padding: 0;
    padding-top: 32px;
  }
`

export const MicrosoftLog = styled.img`
  width: 103px;
  height: 22px;
  margin-top: 36px;
`

export const Title = styled.h1`
  color: var(--highlightColor);
  font-size: 24px;
  padding-bottom: 8px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;

  /* Tablet */
  @media (min-width: 768px) {
    font-size: 32px;
  }
`

export const Info = styled.span`
  color: var(--primaryTextColor);
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  overflow-wrap: break-word;
  width: 239px;
  height: 24px;
  line-height: 150%;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 32px;

  /* Tablet */
  @media (min-width: 768px) {
    width: 100%;
    margin-bottom: 40px;
    margin-top: 12px;
  }
`

export const LoginButton = styled(Button)`
  height: 46px;

  /* Desktop */
  @media (min-width: 1025px) {
    width: 456px;
  }
`
