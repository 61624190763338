import {
  Container,
  PageContainer,
  Paragraph,
  ContainerInput,
  TextInput,
  ButtonContainer,
  Button,
} from './styles'
import { Header } from 'components/Header'

import { useState } from 'react'

import { Loader } from 'components/Loader'
import { MessageElement } from 'components/Message'
import { HiOutlineExclamationCircle, HiCheckCircle } from 'react-icons/hi'

import { uploadFile } from 'react-s3'
import bucketConfig from 'config/bucketConfig'
import { Buffer } from 'buffer'
import { msalInstance } from 'config/msalInstance'

window.Buffer = window.Buffer || Buffer

export const Home = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')

  const [selectedFile, setSelectedFile] = useState<File>()
  const account = msalInstance.getActiveAccount()

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files

    if (!fileList) {
      handleErrorMessage('Selecione um arquivo')
      return
    }

    setSelectedFile(fileList[0])
  }

  const padTo2Digits = (num: number) => {
    return num.toString().padStart(2, '0')
  }

  const formatDate = (date: Date) => {
    return (
      [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join(
        '-',
      ) +
      '_' +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join('-')
    )
  }

  const handleErrorMessage = (message: string) => {
    setMessage(message)
    setError(true)
    setTimeout(() => {
      setError(false)
      setMessage('')
    }, 10000)
  }

  const handleUpload = async () => {
    setLoading(true)
    try {
      if (!selectedFile?.size || !selectedFile?.type) {
        handleErrorMessage('Selecione um arquivo')
        return
      }

      const MAX_FILE_SIZE = 1024 * 20 // 20MB

      const fileSizeKiloBytes = selectedFile?.size / 1024

      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        handleErrorMessage('O arquivo pode ter no máximo 20MB')
        return
      }

      if (
        selectedFile?.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        handleErrorMessage('O arquivo precisa ser do tipo xlsx, conforme o template')
        return
      }
      const fileDate = formatDate(new Date())

      const dotIndex = selectedFile?.name.lastIndexOf('.')
      const fileName = selectedFile?.name.slice(0, dotIndex).toLowerCase()

      const fileExtension = selectedFile?.name
        .slice(dotIndex + 1, selectedFile?.name.length)
        .toLowerCase()

      const blob = selectedFile?.slice(0, selectedFile.size, selectedFile.type)
      const newFile = new File(
        [blob as BlobPart],
        `${fileName.replace(/ /g, '_')}_${fileDate}_${account?.username}.${fileExtension}`,
        {
          type: selectedFile?.type,
        },
      )

      const uploaded = await uploadFile(newFile, bucketConfig)

      if (uploaded.result.status !== 204) {
        setMessage('Error ao carregar arquivo')
        setError(true)
      } else {
        setMessage('Sucesso no carregamento do arquivo')
        setError(false)
      }
    } catch (error) {
      setError(true)
      setMessage('Error ao carregar arquivo')
    } finally {
      setLoading(false)
      setTimeout(() => {
        setError(false)
        setMessage('')
      }, 10000)
    }
  }

  return (
    <>
      {message && (
        <MessageElement
          text={message}
          primaryColor={error ? '#FCE8DB' : '#EDFBD8'}
          secondaryColor={error ? '#BA1600' : '#2B641E'}
          icon={error ? HiOutlineExclamationCircle : HiCheckCircle}
        />
      )}
      <Header />

      <Container>
        <PageContainer>
          {loading ? (
            <>
              <Loader message1='Estamos processando' message2='o seu arquivo' />
            </>
          ) : (
            <>
              <Paragraph>Para atualizar os status dos pedidos faço o upload da planilha</Paragraph>
              <ContainerInput>
                <TextInput
                  type='file'
                  onChange={handleFileInput}
                  placeholder='Selecione um arquivo'
                />
              </ContainerInput>
              <ButtonContainer>
                <Button onClick={() => handleUpload()}> Fazer upload </Button>
              </ButtonContainer>
            </>
          )}
        </PageContainer>
      </Container>
    </>
  )
}
