import { Configuration, RedirectRequest } from '@azure/msal-browser'

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: 'a253ffc0-8912-4938-8065-439b7ad963ea',
    authority: 'https://login.microsoftonline.com/f558b0e4-c400-4992-b98a-3e1cecae578d',
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URL || 'http://localhost:3000',
    postLogoutRedirectUri: '/',
  },
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: ['User.Read'],
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft-ppe.com/v1.0/me',
}
