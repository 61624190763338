import { Button } from 'components/Button'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 94px;

  /* Tablet */
  @media (min-width: 768px) {
    margin-top: 120px;
    width: 100%;
  }

  /* Desktop */
  @media (min-width: 1025px) {
    margin-left: 100px;
    margin-top: 0;
    height: 95vh;
    width: calc(100% - 100px);
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 94px;
  padding: 0 32px;
  width: calc(100% - 64px);

  /* Tablet */
  @media (min-width: 768px) {
  }

  /* Desktop */
  @media (min-width: 1025px) {
    margin-top: 120px;
    margin-bottom: 120px;
    width: 416px;
  }
`

export const Title = styled.h1`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  font-family: 'Poppins', sans-serif;
  color: var(--primaryTextColor);
  margin-bottom: 4px;

  /* Tablet */
  @media (min-width: 768px) {
    font-size: 20px;
  }
`

export const Message = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  font-family: 'Roboto', sans-serif;
  color: var(--primaryTextColor);
  margin-bottom: 40px;

  /* Tablet */
  @media (min-width: 768px) {
    font-size: 20px;
    margin-bottom: 60px;
  }
`

export const Image = styled.img`
  width: 274px;
  margin-bottom: 48px;

  /* Tablet */
  @media (min-width: 768px) {
    width: 416px;
    margin-bottom: 56px;
  }

  /* Desktop */
  @media (min-width: 1025px) {
    width: 316px;
  }
`

export const StyledButton = styled(Button)`
  /* Tablet */
  @media (min-width: 768px) {
    width: 362px;
  }
`
