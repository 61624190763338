export default {
  title: 'mobile',
  colors: {
    background: '#fff',
    highlightColor: '#6460AA',
    highlightText: '#fff',
    primaryTextColor: '#6D6E71',
    secondaryTextColor: '#2F3132',
    searchBackground: '#FCFCFC',
  },
}
