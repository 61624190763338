import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { Navigate } from 'react-router-dom'
import LoginBannerDesktop from 'assets/login_banner_desktop.png'
import Microsoft from 'assets/microsoft.svg'
import {
  Container,
  CoverImg,
  ContainerImgs,
  LoginContainer,
  Title,
  Info,
  LoginCard,
  LoginButton,
  MicrosoftLog,
} from './styles'
import { loginRequest } from '../../config/authConfig'

export const Login = () => {
  const { instance } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  const handleLogin = () => {
    instance.loginRedirect(loginRequest)
  }

  if (isAuthenticated) {
    return <Navigate to='/' state={{ from: location }} />
  }

  return (
    <Container>
      <ContainerImgs>
        <CoverImg src={LoginBannerDesktop} alt='Cover logo' />
      </ContainerImgs>

      <LoginContainer>
        <LoginCard>
          <Title>Login</Title>

          <Info>Acesse status transportadora</Info>

          <LoginButton onClick={() => handleLogin()}>Entrar com Microsoft</LoginButton>

          <MicrosoftLog src={Microsoft} alt='Microsoft ' />
        </LoginCard>
      </LoginContainer>
    </Container>
  )
}
