import { IconBaseProps } from 'react-icons/lib'
import { Container, Text } from './styles'

interface MessageTextProps {
  text?: string
  primaryColor: string
  secondaryColor: string
  icon?: React.ComponentType<IconBaseProps>
}

export const MessageElement = ({
  text,
  primaryColor,
  secondaryColor,
  icon: Icon,
}: MessageTextProps) => {
  return (
    <Container color={primaryColor}>
      <Text color={secondaryColor}>{text}</Text>
      {Icon && <Icon size={20} color={secondaryColor} />}
    </Container>
  )
}
