/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext/AuthContext'
import { AuthContextType } from '../types/AuthContextType'

const useAuth = () => {
  return useContext<AuthContextType>(AuthContext as any)
}

export default useAuth
