/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMsal } from '@azure/msal-react'
import { useEffect, useState } from 'react'
import { HiOutlineLogout } from 'react-icons/hi'
import { Account } from 'types/Account'
import {
  Container,
  GreetingContainer,
  Greeting,
  UserName,
  SignOutContainer,
  SignOutText,
  Divider,
} from './styles'

export const Header = () => {
  const [account, setAccount] = useState<Account>({} as Account)

  const { instance }: any = useMsal()

  const handleLogout = () => {
    instance.logoutRedirect()
  }

  useEffect(() => {
    const accountInstance = instance.getActiveAccount()

    if (accountInstance) {
      setAccount(accountInstance)
    }
  }, [])

  return (
    <Container>
      <GreetingContainer>
        <Greeting>Olá,</Greeting>

        <UserName>{account.name}</UserName>
      </GreetingContainer>

      <Divider />

      <SignOutContainer onClick={() => handleLogout()}>
        <SignOutText>Sair</SignOutText>

        <HiOutlineLogout size={24} />
      </SignOutContainer>
    </Container>
  )
}
