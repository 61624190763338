import { useState } from 'react'
import { AuthContext } from './AuthContext'

type Props = {
  children?: React.ReactNode
}
export const AuthProvider = ({ children }: Props) => {
  const [auth, setAuth] = useState(null)

  return <AuthContext.Provider value={{ auth, setAuth }}>{children}</AuthContext.Provider>
}

export default AuthProvider
