import { Routes, Route } from 'react-router-dom'
import { Home } from '../pages/Home'
import { Login } from '../pages/Login'
import { PersistLogin } from './PersistLogin'
import { ErrorScreen } from 'components/ErrorScreen'
import { Header } from 'components/Header'

export const ProjectRouter = () => {
  return (
    <Routes>
      <Route path='/login' element={<Login />} />

      <Route element={<PersistLogin />}>
        <Route path='/' element={<Home />} />
      </Route>

      <Route
        path='*'
        element={
          <>
            <Header />

            <ErrorScreen
              title='Página não encontrada'
              message='A página não existe ou foi removida'
            />
          </>
        }
      />
    </Routes>
  )
}
